import React, { Suspense, useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { ISaveShop } from '../hooks/useSaveShopsQuery'
import { RepairShopMapViewportRef } from './RepairShopMapViewport'
import RepairShopMapList from './RepairShopMapList'
import RepairShopMapForm from './RepairShopMapForm'
import { useForm } from 'react-hook-form'
import { MapSearchFormData } from '../models/MapSearchFormData'
import mapSearchFormSchema from '../validations/mapSearchFormSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { SaveShop } from '../models/SaveShop'
import { SaveShopParams } from '../models/SaveShopParams'
import fetchSaveApi from '../utils/fetchSaveApi'
import InputMapBoxField from './InputMapBoxField'

const RepairShopMapViewportLazy = React.lazy(
  () => import('./RepairShopMapViewport')
)

const Container = styled.div`
  position: relative;
  min-height: 380px;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    min-height: 546px;
  }
  & > *[class^='RepairShopMap__SideBar'] {
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      position: absolute;
      left: 6px;
      bottom: 5px;
      z-index: 401;
      max-width: 360px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 7px;
    }
    & > form {
      padding: 14px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.background};
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding: 7px;
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }
    }
    & > *[class^='RepairShopMapList'] {
      min-height: 200px;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding: 4px 6px;
        border-radius: 5px;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
        background: #ffffff;
        min-height: 320px;
        position: relative;
      }
      & > ul {
        @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
          position: absolute;
          overflow: auto;
          top: 4px;
          bottom: 4px;
          left: 6px;
          right: 6px;
        }
      }
    }
  }
  & > *[class*='RepairShopMapRender'] {
    min-height: min(380px, 70vh);
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
      min-height: 546px;
    }
  }
`

const SideBar = styled.div``

interface RepairShopMapProps {
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onSelected?: (shopId: string) => void
  pathBuilder?: (shopId: string) => string | undefined
  brandName?: string
}

const RepairShopMap: React.FC<RepairShopMapProps> = ({
  onChange,
  onSelected,
  pathBuilder,
  brandName,
}) => {
  const isSSR = typeof window === 'undefined'
  const [shops, setShops] = useState<ISaveShop[]>([])
  const mapRef = useRef<RepairShopMapViewportRef>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleClick = useCallback(
    (shopId: string) => {
      mapRef.current?.centerTo(shopId, { openPopup: true })
    },
    [mapRef]
  )

  const handleSelected = useCallback(
    (shopId: string) => {
      if (!inputRef.current) {
        return
      }

      // trigger onchange event
      Object.getOwnPropertyDescriptor(
        HTMLInputElement.prototype,
        'value'
      )?.set?.call(inputRef.current, shopId)
      inputRef.current.dispatchEvent(new Event('change', { bubbles: true }))

      onSelected && onSelected(shopId)
    },
    [inputRef, onSelected]
  )

  const [geoLoading, setGeoLoading] = useState(true)

  const {
    register,
    setValue,
    setError,
    handleSubmit,
    formState: { isSubmitting: isSubmittingForm },
  } = useForm<MapSearchFormData>({
    resolver: yupResolver(mapSearchFormSchema),
  })

  const onSubmit = handleSubmit(async (data) => {
    const defaultParams: SaveShopParams =
      brandName?.toUpperCase() === 'SAMSUNG'
        ? {
            samsungLevel: 'true',
          }
        : brandName === 'APPLE'
        ? {
            appleLevel: 'true',
          }
        : {}
    const params: SaveShopParams =
      data.lat && data.lng
        ? {
            ...defaultParams,
            longitude: data.lng.toString(),
            latitude: data.lat.toString(),
          }
        : {
            ...defaultParams,
            postalCode: data.zip,
          }
    const searchParams = new URLSearchParams(params)
    const shops =
      (await fetchSaveApi<SaveShop[]>(
        `/website/v1/shops?${searchParams.toString()}`
      )) ?? []

    setShops(shops)
    setGeoLoading(false)

    if (shops.length > 0) {
      const shop = shops[0]
      mapRef.current?.centerTo(shop.shopId)
    }
  })

  const isSubmitting = useMemo(
    () => isSubmittingForm || geoLoading,
    [geoLoading, isSubmittingForm]
  )

  const submitRef = useRef<HTMLInputElement | null>(null)

  const handleGeo = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()

      setGeoLoading(true)
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          setGeoLoading(false)

          setValue('query', '')
          setValue('zip', '')
          setValue('lat', coords.latitude)
          setValue('lng', coords.longitude)
          submitRef.current?.click()
        },
        (err) => {
          setGeoLoading(false)

          const message =
            err.code === 1
              ? 'Veuillez activer la géolocalisation'
              : 'Impossible d’otenir votre position'

          setError('query', { message })
        },
        {
          enableHighAccuracy: true,
          timeout: 30000,
          maximumAge: 0,
        }
      )
    },
    []
  )

  const handleReady = useCallback(() => {
    // by default center on "Save by PSM Paris Etoile"
    setValue('zip', '75017')
    setValue('lat', 48.8772)
    setValue('lng', 2.29569)
    submitRef.current?.click()
  }, [])

  const el =
    typeof document !== 'undefined'
      ? document.querySelector('ge-autocomplete')
      : null

  if (el) {
    el.addEventListener('select', (event) => {
      setValue('zip', event.detail.properties.postalcode)
      setValue('lat', event.detail.geometry.coordinates[1])
      setValue('lng', event.detail.geometry.coordinates[0])
      submitRef.current?.click()
    })
  }

  const onUpdate = (zip: string, lat: number, lng: number) => {
    setValue('zip', zip)
    setValue('lat', lat)
    setValue('lng', lng)
    submitRef.current?.click()
  }

  return (
    <Container>
      <input style={{ display: 'none' }} ref={inputRef} onChange={onChange} />
      <SideBar>
        {/*<RepairShopMap3 />*/}
        <RepairShopMapForm onSubmit={onSubmit}>
          {/*<GlobalStyle />*/}

          <InputMapBoxField onUpdate={onUpdate} />

          <RepairShopMapForm.Control>
            <RepairShopMapForm.GeoButton
              type="button"
              disabled={isSubmitting}
              onClick={handleGeo}
            >
              Être géolocalisé
            </RepairShopMapForm.GeoButton>
            <RepairShopMapForm.Submit
              disabled={isSubmitting}
              value="Trouver mon magasin"
            />
          </RepairShopMapForm.Control>

          <input type="submit" ref={submitRef} style={{ display: 'none' }} />
        </RepairShopMapForm>

        <RepairShopMapList
          loading={isSubmitting}
          items={shops}
          brandName={brandName}
          onClick={handleClick}
          onSelected={handleSelected}
          pathBuilder={pathBuilder}
        />
      </SideBar>
      {!isSSR && (
        <Suspense fallback={<div />}>
          <RepairShopMapViewportLazy
            ref={mapRef}
            onSelected={handleSelected}
            onReady={handleReady}
          />
        </Suspense>
      )}
    </Container>
  )
}

export default RepairShopMap
