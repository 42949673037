import { PageProps } from 'gatsby'
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import QuoteContainer from '../components/QuoteContainer'
import QuoteHeader from '../components/QuoteHeader'
import RepairContent from '../components/RepairContent'
import RepairShopMap from '../components/RepairShopMap'
import scrollIntoView from 'smooth-scroll-into-view-if-needed'
import AnchorScroll from '../components/AnchorScroll'
import QuoteForm from '../components/QuoteForm'
import Form from '../components/Form'
import QuoteShopInfos from '../components/QuoteShopInfos'
import { LayoutProps } from '../components/Layout.context'
import useSaveShopsQuery from '../hooks/useSaveShopsQuery'
import { QuoteCustomFormData } from '../models/QuoteCustomFormData'
import { yupResolver } from '@hookform/resolvers/yup'
import quoteCustomFormSchema, {
  modelCustomValue,
} from '../validations/quoteCustomFormSchema'
import { useForm } from 'react-hook-form'
import warrantyOptions from '../data/warrantyOptions'
import howFoundSaveOptions from '../data/howFoundSaveOptions'
import useFormError from '../hooks/useFormError'
import QuoteSuccessContainer from '../components/QuoteSuccessContainer'
import QuoteSuccessCard from '../components/QuoteSuccessCard'
import { QuoteCustomRequest } from '../models/QuoteCustomRequest'
import fetchSaveApi from '../utils/fetchSaveApi'
import useQuoteCustomBrandsOptions from '../hooks/useQuoteCustomBrandsOptions'
import useQuoteCustomModelsOptions from '../hooks/useQuoteCustomModelsOptions'
import { QuoteCustomResponse } from '../models/QuoteCustomResponse'
import { Helmet } from 'react-helmet'
import Banner from '../components/Banner'
import { Option as IOption } from '../models/Option'

const QuotePage: React.FC<PageProps<unknown, unknown>> = ({ pageContext }) => {
  const shops = useSaveShopsQuery()
  const [shopId, setShopId] = useState<string | undefined>(undefined)
  const ref = useRef<HTMLDivElement | null>(null)

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const shopId = e.target.value
      setShopId(shopId)
    },
    [pageContext]
  )

  const handleSelected = useCallback(() => {
    requestAnimationFrame(() => {
      if (ref.current) {
        scrollIntoView(ref.current, {
          scrollMode: 'always',
          block: 'start',
        })
      }
    })
  }, [])

  const shop = useMemo(() => {
    return shops.find((x) => x.shopId === shopId)
  }, [shops, shopId])

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<QuoteCustomFormData>({
    resolver: yupResolver(quoteCustomFormSchema),
  })

  const [formError, setFormError] = useFormError(isSubmitting)

  const [result, setResult] = useState<QuoteCustomFormData | undefined>(
    undefined
  )

  const onSubmit = handleSubmit(async (data) => {
    data.brand = brandSelected ? brandSelected.value : ''
    data.modele = modelSelected ? modelSelected.value : ''
    data.garantee = garantieSelected ? garantieSelected.value : ''
    data.howFoundSave = howSelected ? howSelected.value : ''
    const request: QuoteCustomRequest = {
      shopId: data.shopId,
      magasinid: data.shopId,
      brand: data.brand,
      modele:
        data.modele === modelCustomValue && data.modeleCustom
          ? data.modeleCustom
          : data.modele,
      webSiteOrigin: 'SAVE_CO',
      garantee: data.garantee,
      description: data.description,
      first_name: data.firstname,
      last_name: data.lastname,
      email: data.email,
      optin1: data.optin1,
      optin2: data.optin2,
      address: data.street,
      zip_code: data.zip,
      city: data.city,
      hgHomeProcess: false,
      devisDetailAsList: [],
      isFromSaveCo: true,
    }

    try {
      const resp = await fetchSaveApi<QuoteCustomResponse>(
        '/website/v1/quote/form',
        {
          method: 'POST',
          body: JSON.stringify(request),
        }
      )

      if (!resp?.reference) {
        setFormError({
          type: 'required',
          message: "Une erreur est survenue lors de l'envoi du devis",
        })
        return
      }

      setResult(data)
      requestAnimationFrame(() => scrollTo(0, 0))
    } catch (e) {
      console.error(e)
      setFormError({
        type: 'required',
        message: "Une erreur est survenue lors de l'envoi du devis",
      })
    }
  })

  useEffect(() => {
    if (shopId) {
      setValue('shopId', shopId)
    }
  }, [shopId])

  const [brandSelected, setBrandSelected] = useState<IOption>()
  const [modelSelected, setModelSelected] = useState<IOption>()
  const [garantieSelected, setGarantieSelected] = useState<IOption>()
  const [howSelected, sethowSelected] = useState<IOption>()

  const selectBrand = useCallback(
    (optionSelected: IOption) => {
      setBrandSelected(optionSelected)
      setModelSelected({ label: 'Sélectionnez votre modèle', value: '' })
    },
    [brandSelected]
  )

  const modelEnabled = useMemo(
    () => brandSelected && brandSelected.value.trim().length > 0,
    [brandSelected]
  )
  const quoteCustomBrandsOptions = useQuoteCustomBrandsOptions()
  let modelOptionsOriginal = useQuoteCustomModelsOptions(
    brandSelected?.value ?? ''
  )
  const modelOptions = useMemo(
    () => [
      { label: 'Mon modèle n‘est pas dans la liste', value: modelCustomValue },
      ...modelOptionsOriginal,
    ],
    [modelOptionsOriginal]
  )

  const showModelCustom = useMemo(
    () => modelSelected?.value === modelCustomValue,
    [modelSelected]
  )
  if (!result) {
    return (
      <>
        <Helmet>
          <meta
            name="description"
            content="Réalisez une demande de devis sur le site de Save !"
          />
        </Helmet>

        <QuoteContainer>
          <QuoteHeader
            title={<>Suivez le Guide !</>}
            description={<>Effectuez un devis auprès de votre magasin</>}
          />

          <RepairContent.Card
            index={1}
            title={
              <RepairContent.Title floatingTitleOnDesktop>
                Sélectionnez le magasin qui vous convient
              </RepairContent.Title>
            }
          >
            <RepairShopMap
              onChange={handleChange}
              onSelected={handleSelected}
            />
          </RepairContent.Card>

          <AnchorScroll ref={ref} />

          {shopId && (
            <QuoteForm onSubmit={onSubmit}>
              <RepairContent.Card
                index={2}
                title={
                  <RepairContent.Title>
                    <QuoteShopInfos>
                      <QuoteShopInfos.Title>
                        Complétez les éléments à propos de votre appareil et sa
                        panne
                      </QuoteShopInfos.Title>
                      <QuoteShopInfos.Name>
                        {shop?.shopName}
                      </QuoteShopInfos.Name>
                      <QuoteShopInfos.Address>
                        {shop?.shopFullAddress}
                      </QuoteShopInfos.Address>
                    </QuoteShopInfos>
                  </RepairContent.Title>
                }
              >
                <QuoteForm.DeviceContainer>
                  <Form.Select
                    className="brand"
                    error={errors.brand}
                    value={brandSelected}
                    onChange={selectBrand}
                    options={quoteCustomBrandsOptions}
                    placeholder="Sélectionnez la marque de votre produit"
                  />
                  <Form.Select
                    className="model"
                    options={modelOptions}
                    onChange={setModelSelected}
                    value={modelSelected}
                    error={errors.modele}
                    placeholder="Sélectionnez votre modèle de votre produit"
                    disabled={!modelEnabled}
                  />
                  {showModelCustom && (
                    <Form.Input
                      className="modelCustom"
                      {...register('modeleCustom')}
                      error={errors.modeleCustom}
                      placeholder="Votre modèle"
                    />
                  )}
                  <Form.Select
                    value={garantieSelected}
                    onChange={setGarantieSelected}
                    className="garantee"
                    error={errors.garantee}
                    options={warrantyOptions}
                    placeholder="État de la garantie"
                  />
                  <Form.Textarea
                    className="description"
                    {...register('description')}
                    error={errors.description}
                    minRows={6}
                    placeholder="Description de la panne"
                  />
                </QuoteForm.DeviceContainer>
              </RepairContent.Card>

              <RepairContent.Card
                index={3}
                title={
                  <RepairContent.Title>
                    Renseignez vos informations de contact afin que le magasin
                    revienne vers vous dans les plus brefs délais
                  </RepairContent.Title>
                }
              >
                <QuoteForm.ContactContainer>
                  <Form.Input
                    {...register('email')}
                    error={errors.email}
                    placeholder="Adresse mail"
                  />
                  <Form.Input
                    {...register('lastname')}
                    error={errors.lastname}
                    placeholder="Nom"
                  />
                  <Form.Input
                    {...register('firstname')}
                    error={errors.firstname}
                    placeholder="Prénom"
                  />
                  <Form.Input
                    {...register('street')}
                    error={errors.street}
                    placeholder="Adresse"
                  />
                  <Form.Input
                    {...register('zip')}
                    error={errors.zip}
                    placeholder="Code postal"
                  />
                  <Form.Input
                    {...register('city')}
                    error={errors.city}
                    placeholder="Ville"
                  />
                  <Form.Select
                    value={howSelected}
                    onChange={sethowSelected}
                    error={errors.howFoundSave}
                    options={howFoundSaveOptions}
                    placeholder="Comment avez-vous connu Save ?"
                  />
                  <Form.Options>
                    <Form.Option
                      {...register('optin1')}
                      error={errors.optin1}
                      label="J’accepte de recevoir des emails ciblés et automatiques en fonction de mes données de navigation et de mes intérêts."
                    />
                    <Form.Option
                      {...register('optin2')}
                      error={errors.optin2}
                      label="J’accepte de recevoir des promotions ponctuelles (soldes, opérations spéciales...) de Save."
                    />
                  </Form.Options>
                </QuoteForm.ContactContainer>
              </RepairContent.Card>

              <Form.Submit
                disabled={isSubmitting}
                value="Valider ma demande de devis"
                error={formError}
              />
            </QuoteForm>
          )}
        </QuoteContainer>
      </>
    )
  }

  return (
    <QuoteSuccessContainer>
      <Banner
        type="validation"
        title={<>Votre demande de devis a bien été prise en compte</>}
        description={
          <>
            Vous serez contacté par mail dans les plus brefs délais par :&nbsp;
            {shop?.shopName}
            <br />
            Merci de votre confiance
          </>
        }
      />

      <QuoteSuccessCard>
        <QuoteSuccessCard.Row>
          <QuoteSuccessCard.Title>
            Produit et réparations :
          </QuoteSuccessCard.Title>
          <QuoteSuccessCard.SubTitle>
            Marque, Modèle :
          </QuoteSuccessCard.SubTitle>
          <QuoteSuccessCard.Paragraph>
            {result.brand} {result.modele}
          </QuoteSuccessCard.Paragraph>
          <QuoteSuccessCard.SubTitle>Description :</QuoteSuccessCard.SubTitle>
          <QuoteSuccessCard.Paragraph>
            {result.description}
          </QuoteSuccessCard.Paragraph>
        </QuoteSuccessCard.Row>

        <QuoteSuccessCard.Row>
          <QuoteSuccessCard.Title>Mes informations :</QuoteSuccessCard.Title>
          <QuoteSuccessCard.SubTitle>
            Nom, Prénom, Adresse :
          </QuoteSuccessCard.SubTitle>
          <QuoteSuccessCard.Paragraph>
            {result.lastname} {result.firstname}
            <br />
            {result.street} {result.zip} {result.city}
          </QuoteSuccessCard.Paragraph>
          <QuoteSuccessCard.SubTitle>
            Boutique sélectionnée :
          </QuoteSuccessCard.SubTitle>
          <QuoteSuccessCard.Paragraph>
            {shop?.shopName}
            <br />
            {shop?.shopFullAddress}
          </QuoteSuccessCard.Paragraph>
        </QuoteSuccessCard.Row>
      </QuoteSuccessCard>
    </QuoteSuccessContainer>
  )
}

const layoutProps: LayoutProps = {
  title: 'Vous avez une demande particulière ?',
}

export default Object.assign(QuotePage, {
  layoutProps,
})
