import { SetStateAction, useEffect, useState } from 'react'
import useDebounce from '../utils/use-debounce'

const UseInput = (initialValue: string) => {
  const [value, setValue] = useState(initialValue)
  const [suggestions, setSuggestions] = useState([])
  const debouncedSearchTerm = useDebounce(value, 500)

  const mapBoxApiToken =
    'pk.eyJ1Ijoic2F2ZWdyb3VwIiwiYSI6ImNsMzh1cjhpMDAzanYzanFtc2VyeW1pOWEifQ.wvTOc8uliv1YB52jOtHX6g'

  const handleChange = (event: {
    target: { value: SetStateAction<string> }
  }) => {
    setValue(event.target.value)
  }

  const mapbox = async () => {
    try {
      const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${debouncedSearchTerm}.json?access_token=${mapBoxApiToken}&autocomplete=true&country=fr&types=country,postcode,place,locality,address`
      const response = await fetch(endpoint)
      const results = await response.json()
      setSuggestions(results?.features)
    } catch (error) {
      console.log('Error fetching data, ', error)
    }
  }

  useEffect(() => {
    mapbox()
  }, [debouncedSearchTerm])

  return {
    value,
    onChange: handleChange,
    setValue,
    suggestions,
    setSuggestions,
  }
}

export default UseInput
