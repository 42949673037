import { Option } from '../models/Option'

const howFoundSaveOptions: Option[] = [
  { label: 'Je connais déjà PSM', value: 'WALK_SHOP' },
  { label: 'Entourage', value: 'WORD_OF_MOUTH' },
  { label: 'Journaux/TV/Site Partenaire', value: 'NEWSPAPER_ADVERT' },
  { label: 'Google', value: 'GOOGLE_SEARCH' },
  { label: 'Facebook', value: 'FACEBOOK' },
  { label: 'Twitter', value: 'TWITTER' },
  { label: 'Linkedin', value: 'LINKEDIN' },
  { label: 'Forum / Blog', value: 'FORUM_BLOG' },
  { label: 'Autre', value: 'OTHER' },
]

export default howFoundSaveOptions
