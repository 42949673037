import styled from 'styled-components'
import RepairContent, { cardMaxWith, cardSizing } from './RepairContent'

const QuoteContainer = styled(RepairContent)`
  & > *[class^='QuoteHeader'] {
    max-width: ${cardMaxWith}px;
    margin: 0 auto;
  }

  & > form {
    & > *[class^='RepairContent__CardContainer'] {
      ${cardSizing}
      margin-top: 25px !important;
    }

    & > *[class^='Form__SubmitContainer'] {
      margin: 35px auto 0 auto;
    }
  }
`

export default QuoteContainer
