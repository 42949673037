import { Option } from '../models/Option'

const warrantyOptions: Option[] = [
  { label: 'Hors garantie', value: 'out' },
  { label: 'Je ne sais pas', value: 'unknown' },
  { label: 'Mobile cassé', value: 'broken' },
  { label: 'Sous garantie', value: 'ok' },
  { label: 'Mobile oxydé', value: 'oxidized' },
]

export default warrantyOptions
