import * as yup from 'yup'
import { MapSearchFormData } from '../models/MapSearchFormData'

const mapSearchFormSchema: yup.SchemaOf<MapSearchFormData> = yup
  .object({
    query: yup.string(),
    zip: yup.string(),
    lat: yup.number(),
    lng: yup.number(),
  })
  .test('', ({ zip, lat, lng }) => {
    if ((zip && zip.trim().length > 0) || (lat && lng)) {
      return true
    }

    return new yup.ValidationError(
      'Veuillez chercher une adresse ou vous géolocaliser',
      undefined,
      'query',
      'required'
    )
  })
  .defined()

export default mapSearchFormSchema
