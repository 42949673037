import React from 'react'
import styled from 'styled-components'
import UseInput from './UseInput'

interface Props {
  onUpdate: (zip: string, lat: number, lng: number) => void
}
const InputMapBoxField: React.FC<Props> = ({ onUpdate: update }) => {
  const address = UseInput('')

  return (
    <Wrapper>
      <Input
        placeholder="Votre adresse, code postal, ville"
        {...address}
        isTyping={address.value !== ''}
      />
      {address.suggestions?.length > 0 && (
        <SuggestionWrapper>
          {address.suggestions.map((suggestion, index) => {
            return (
              <>
                <Suggestion
                  key={index}
                  onClick={() => {
                    let postalCode = '75017'
                    if (suggestion.context && suggestion.context.length > 0) {
                      for (const ctx of suggestion.context) {
                        if (ctx.id && ctx.id.includes('postcode') && ctx.text) {
                          postalCode = ctx.text
                        }
                      }
                    }
                    update(
                      postalCode,
                      suggestion.geometry.coordinates[1],
                      suggestion.geometry.coordinates[0]
                    )
                    address.setSuggestions([])
                  }}
                >
                  {suggestion.place_name}
                </Suggestion>
                <hr />
              </>
            )
          })}
        </SuggestionWrapper>
      )}
    </Wrapper>
  )
}

export default InputMapBoxField

const Wrapper = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`

const Input = styled.input<{ isTyping: Boolean }>`
  width: 100%;
  background: white;
  border: 1px solid grey;
  padding: 10px 20px;
  border-radius: 30px;
  display: grid;
  justify-self: center;
  &:focus {
    outline: none;
    border-radius: ${(props) => props.isTyping && '10px 10px 0px 0px'};
  }
`

const SuggestionWrapper = styled.div`
  background: white;
  position: absolute;
  width: 350px;
  padding: 10px 20px;
  maring-top: 2px;
  border-radius: 5px 5px 10px 10px;
  border: 1px solid grey;
  margin-top: 3px;
  z-index: 700;
`

const Suggestion = styled.p`
  cursor: pointer;
  max-width: 350px;
`
