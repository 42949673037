import React, { forwardRef } from 'react'
import { FieldError } from 'react-hook-form'
import styled from 'styled-components'
import Form from './Form'

const InputWrapper = styled.input`
  background: ${({ theme }) => theme.colors.background};
  border: none;
  -webkit-appearance: none; // fix iOS display
  color: ${({ theme }) => theme.colors.body};
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  width: 100%;
  padding: 8px 12px;

  &::placeholder {
    text-decoration-line: underline;
    opacity: 0.8;
    color: ${({ theme }) => theme.colors.body};
  }

  &:focus {
    outline: none;
  }
`

interface InputProps {
  placeholder?: string
  name?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
  onBlur?: React.ChangeEventHandler<HTMLInputElement> | undefined
  error?: FieldError
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ name, placeholder, onChange, onBlur, error }, ref) => (
    <span>
      <InputWrapper
        ref={ref}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && <Form.Error>{error.message}</Form.Error>}
    </span>
  )
)

const GeoButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-decoration: underline;
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.secondary};
  -webkit-appearance: none; // fix iOS display
  padding: 0;
`

const SubmitWrapper = styled.input`
  border: none;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 36px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  padding: 8px 16px;
  -webkit-appearance: none; // fix iOS display
  cursor: pointer;
  transition: all 300ms ease;

  &:hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`

interface SubmitProps {
  disabled?: boolean
  value?: string
}

const Submit: React.FC<SubmitProps> = ({ disabled, value }) => (
  <SubmitWrapper type="submit" value={value} disabled={disabled} />
)

const RepairShopMapForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 7px;
`

const Control = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;

  & > span {
    width: 100%;
  }
`

export default Object.assign(RepairShopMapForm, {
  Input,
  GeoButton,
  Control,
  Submit,
})
