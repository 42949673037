import styled from 'styled-components'

const QuoteShopInfos = styled.div`
  & > * {
    display: block;
  }

  & > *[class^='QuoteShopInfos__Name'] {
    margin-top: 10px;
  }
`

const Title = styled.span``

const Name = styled.strong`
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 20px;
    line-height: 24px;
  }
`

const Address = styled.strong`
  font-weight: 350;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 18px;
    line-height: 22px;
  }
`

export default Object.assign(QuoteShopInfos, {
  Title,
  Name,
  Address,
})
