import styled from 'styled-components'

const Row = styled.div`
  padding: 23px 26px;

  & > *[class^='QuoteSuccessCard__Title'] {
    margin-bottom: 10px;
  }

  & > *[class^='QuoteSuccessCard__SubTitle'] {
    margin-bottom: 4px;
  }

  & > *[class^='QuoteSuccessCard__Paragraph'] {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`

const Title = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.secondary};
`

const SubTitle = styled.strong`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.primary};
  display: block;
`

const Paragraph = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.placeholder};
`

const QuoteSuccessCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: flex;
    align-items: stretch;

    & > * {
      flex: 1;
    }
  }

  & > :first-child {
    position: relative;

    &::after {
      content: '';
      height: 1px;
      background-color: ${({ theme }) => theme.colors.muted};
      width: calc(100% - 2 * 20px);
      display: block;
      position: absolute;
      top: 100%;
      left: 20px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        top: 20px;
        left: 100%;
        width: 1px;
        height: calc(100% - 2 * 20px);
      }
    }
  }
`

export default Object.assign(QuoteSuccessCard, {
  Row,
  Title,
  SubTitle,
  Paragraph,
})
